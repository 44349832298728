<template>
  <div>

<!-- 
    <v-card class="mx-auto overflow-hidden"> -->
      <v-app-bar color="deep-purple" dark id="nav-mobile">
        <!-- <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon> -->

        <!-- <v-toolbar-title>INGASTEC</v-toolbar-title> -->
        <v-row class="div-lista-menu" :style="{margin:'0',padding:'0'}">
          <v-col class="col-3 ma-0 pa-0" :style="{margin:'0',padding:'0',paddingTop:'10%',paddingRight:'calc(8vmin)'}"> <a class="active" @click="Goto('home-mobile')">Inicio</a></v-col>
          <v-col class="col-3 ma-0 pa-0" :style="{margin:'0',padding:'0',paddingTop:'10%',paddingRight:'calc(8vmin)'}">     <a @click="Goto('servicios-generales-mobile')">Servicios
              </a></v-col>
                <v-col class="col-3 ma-0 pa-0" :style="{margin:'0',padding:'0',paddingTop:'10%',paddingRight:'calc(8vmin)'}">     <a @click="Goto('galeria-mobile')">
                Galeria
              </a></v-col>
          <v-col class="col-3 ma-0 pa-0"  :style="{margin:'0',padding:'0',paddingTop:'10%'}">    <a @click="Goto('contacto-mobile')">
                Contacto
              </a></v-col>
         
         
          
            </v-row>
      </v-app-bar>

      <!-- <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav dense>
          <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
            <v-list-item>
              <v-list-item-icon :style="{marginRight:'10vw'}">
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <a class="active" @click="Goto('home')">
                <v-list-item-title>Inicio</v-list-item-title>
              </a>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon :style="{marginRight:'10vw'}">
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <a class="active" @click="Goto('servicios-generales')">
                <v-list-item-title>Servicios</v-list-item-title>
              </a>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon :style="{marginRight:'10vw'}">
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <a class="active" @click="Goto('contacto')">
                <v-list-item-title>Contacto</v-list-item-title>
              </a>
            </v-list-item>


          </v-list-item-group>
        </v-list>
      </v-navigation-drawer> -->
    <!-- </v-card> -->




    <!-- <a  class="active" @click="Goto('home')">Inicio</a>
 <a  @click="Goto('servicios-generales')">Servicios</a>

   <a  @click="Goto('clientes')">Clientes</a>
  <a  @click="Goto('contacto')">Contacto</a> -->


  </div>
</template>

<script>
export default {
  name: "AppLayoutLinksMobile",
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    Goto(seccion) {
      //console.log(seccion)
      if (document.getElementById(seccion)) {
        document.getElementById(seccion).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }
    }
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

#imagenheader {
  position: relative;
  left: 0;
  top: 10vh;
  height: 10vh;
  background-image: url('../../assets/img/logo/imagenes/aa.png');
  background-size: contain;
  z-index: 10;
}
.div-lista-menu{
  display: inline-flexbox;
}



#nav-mobile {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 !important;
  /* padding-top: 2vh; */
  margin-bottom: 1vh;
  /* padding-right: 1vw; */
  width: 100vw !important;
  /* background-color: rgba(250, 252, 251, 1); */
  opacity: 0.6;
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end
}

#nav a {
  font-weight: bold;
  color: #091d01;
  padding-right: 2vw;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  cursor: pointer;
}

#nav a.router-link-exact-active {
  color: #929292;
}
</style>